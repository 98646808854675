var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{attrs:{"data":_vm.items,"header-cell-style":{
      background: '#3c6ef0',
      color: 'white',
      'font-size': '18px',
    },"hide-empty-img":true}},[_c('el-table-column',{attrs:{"label":_vm.type == 1 ? _vm.$t('docContent.sdkDoc.baseSdkName') : _vm.$t('docContent.sdkDoc.businessSdkName')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s((_vm.isInternational && _vm.locale === 'en-us' && _vm.type === 1) ? scope.row.nameSl : scope.row.name))]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('docContent.sdkDoc.version')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.version))]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('docContent.sdkDoc.language')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.language === 1)?_c('div',[_vm._v("JAVA")]):(scope.row.language === 2)?_c('div',[_vm._v(".NET")]):(scope.row.language === 3)?_c('div',[_vm._v("PHP")]):(scope.row.language === 4)?_c('div',[_vm._v("Python")]):(scope.row.language === 5)?_c('div',[_vm._v("Golang")]):_c('div',[_vm._v("UNKNOWN")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('docContent.sdkDoc.sdkDescription')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(_vm.type === 1 ? ((_vm.isInternational && _vm.locale === 'en-us' && _vm.type === 1) ? scope.row.descriptionSl : scope.row.description) : scope.row.remark))]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){_vm.type == 1
              ? _vm.downBasicSdk(scope.row.jssKey, scope.row.fileName)
              : _vm.downBizloadSdk(scope.row.jssKey, scope.row.fileName)}}},[_vm._v(_vm._s(_vm.$t('docContent.sdkDoc.download')))])]}}])})],1),_c('div',{staticClass:"segmentation"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }